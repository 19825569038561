import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { useEffect, useMemo, useRef } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import { Remote } from "../../components/remote/Remote.component";
import type { ChartV2Fragment } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { getChartList, LATEST_LIST_SLUG } from "../../services/Charts";
import { MagicLink, Skeleton } from "../../ui";
import type { PageBlockInterface } from "../Block.component";
import styles from "../../components/Eyebrow.module.scss";

const ChartList = loadable(() => import("./ChartList.component"));

interface ChartListLoaderProps extends PageBlockInterface {
  listSlugOnLoad: string;
  chart: ChartV2Fragment;
  showAsWidget: boolean;
  chartSlug: string;
  store?: Store;
}

export const ChartListLoader: React.FunctionComponent<ChartListLoaderProps> = inject("store")(
  ({ listSlugOnLoad, block, isPrimary, chartSlug, showAsWidget, store, chart }) => {
    const mounted = useRef(false);
    const { t } = useTranslation();

    useEffect(() => {
      mounted.current = true;

      return () => {
        mounted.current = false;
      };
    }, []);

    if (!store || !chartSlug) {
      return null;
    }

    const { site } = store;

    if (!site || !chart) {
      return null;
    }

    const listSlugToFetch = useMemo(
      () => (listSlugOnLoad !== LATEST_LIST_SLUG ? listSlugOnLoad : chart.lists?.items[0]?.slug),
      [chart.lists?.items, listSlugOnLoad],
    );

    if (!listSlugToFetch) {
      return null;
    }

    const { items } = chart.lists;
    const curItemIdx = useMemo(() => items.findIndex(item => item.slug === listSlugToFetch), [listSlugToFetch, items]);

    const chartPath = useMemo(
      () => reverseRoute(site, "chart", { chart_slug: chartSlug }) || `/charts/${chartSlug}`,
      [chartSlug, site],
    );

    const previousListPath = useMemo(() => {
      if (curItemIdx >= 0) {
        return reverseRoute(site, "chart_detail", {
          chart_slug: chartSlug,
          list_slug: chart.lists?.items[curItemIdx + 1]?.slug,
        });
      }
    }, [chartSlug, site, chart.lists?.items, curItemIdx]);

    const previousListLink = previousListPath ? (
      <>
        <MagicLink to={previousListPath}>{t("see_prev_week")}</MagicLink>
        &nbsp;|&nbsp;
      </>
    ) : null;

    return (
      <React.Fragment>
        {!showAsWidget ? (
          <nav className={styles.componentEyebrow}>
            {previousListLink}
            <MagicLink to={chartPath}>{t("see_all_weeks")}</MagicLink>
          </nav>
        ) : null}

        <Remote
          loader={() => getChartList({ slug: listSlugToFetch })}
          cacheKey={`charts_list_loader-${chartSlug}-${listSlugOnLoad}`}
          showLoading={true}
          fallback={<Skeleton pulse={true} type="text" name="chart list" shape="tall" />}
        >
          {({ data: list }) =>
            list ? (
              <ChartList
                showAsWidget={showAsWidget}
                chartTitle={chart.title}
                isPrimary={isPrimary}
                chartSlug={chartSlug}
                block={block}
                list={list}
              />
            ) : null
          }
        </Remote>
      </React.Fragment>
    );
  },
);

export default ChartListLoader;
